// Import React
import { graphql } from 'gatsby';
import { withPreview } from 'gatsby-source-prismic';
import React from 'react';
import { Navbar, PageFooter } from '../components';
import PostCard from '../components/PostCard';
// import our styles
import '../style/style.scss';

export const query = graphql`
  query PostsQuery($limit: Int!, $skip: Int!) {
    allPrismicPost(
      limit: $limit
      skip: $skip
      sort: { fields: data___post_date, order: DESC }
    ) {
      edges {
        node {
          uid
          data {
            post_date
            post_title
            post_seo_title
            post_seo_description
            post_seo_robots
            post_append_site_title
            body {
              ... on PrismicPostBodyTextBlock {
                id
                slice_label
                slice_type
                primary {
                  post_text_content {
                    raw
                  }
                }
              }
              ... on PrismicPostBodyImageBlock {
                id
                slice_label
                slice_type
                primary {
                  post_image {
                    url
                  }
                }
              }
            }
          }
        }
      }
      pageInfo {
        pageCount
        perPage
        totalCount
        currentPage
        hasNextPage
        hasPreviousPage
      }
    }
  }
`;

const Posts = ({ data, aaa, pageContext }) => {
  if (!data) return null;

  const { previousPage, nextPage, settings } = pageContext;
  const { pageInfo, edges } = data.allPrismicPost;

  const previousButton = pageInfo.hasPreviousPage && (
    <a href={previousPage} rel="prev" className="btn btn-primary">
      ← Previous Page
    </a>
  );

  const nextButton = pageInfo.hasNextPage && (
    <a href={nextPage} rel="next" className="btn btn-primary">
      Next Page →
    </a>
  );

  return (
    <main className="page has-parent">
      <Navbar {...settings.data} />
      <div className="container post-container">
        <h1 className="text-center">{'News'}</h1>
        <div className="container">
          <div className="row">
            {edges.map(({ node }) => {
              // get all the images from the post slice_type image_block
              const images = node.data.body.filter(
                (slice) => slice.slice_type === 'image_block'
              );
              // map the images to an array of urls
              const imageUrls = images.map(
                (image) => image.primary.post_image.url
              );
              return (
                <div className="col-12 col-lg-6 p-4" key={node.uid}>
                  <PostCard
                    title={node.data.post_title}
                    date={node.data.post_date}
                    description={node.data.post_seo_description}
                    image={imageUrls ? imageUrls[0] : null}
                    link={`/news/${node.uid}`}
                  />
                </div>
              );
            })}
          </div>
          <div className=" text-center">
            {previousButton} {nextButton}
          </div>
        </div>
      </div>
      <PageFooter />
    </main>
  );
};

export default withPreview(Posts);
